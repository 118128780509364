<template>
  <section class="buy-crypto" data-t="buy-crypto">
    <div class="crypto-badges">
      <div
        v-for="currency in currencies"
        :key="currency.id"
        class="currency-item"
        data-t="currency-item"
      >
        <img :src="currency.icon" :alt="currency.id" width="28" height="28" />
        <span class="code">{{ currency.id }}</span>
      </div>
    </div>
    <div class="crypto-actions" data-t="crypto-actions">
      <div class="crypto-text">
        <p>{{ t('buyCrypto.noCrypto') }}</p>
        <div class="crypto-payments">
          <span>{{ t('buyCrypto.card') }}</span>
          <ul class="crypto-payments-list">
            <li
              v-for="payment in paymentSystems"
              :key="payment.id"
              class="crypto-payments-list-item"
              data-t="payment-item"
            >
              <img :src="payment.icon" :alt="payment.id" height="12" />
            </li>
          </ul>
        </div>
      </div>
      <div class="buy-wrapper">
        <ClientOnly>
          <StButton
            :label="t('buyCrypto.button')"
            type="secondary"
            size="l"
            replace
            :disabled="!isEnabledBuyCrypto"
            :to="{ query: { modal: 'payments', tab: 'buy' } }"
          />
        </ClientOnly>
        <div v-if="!isEnabledBuyCrypto" class="soon">
          {{ t('buyCrypto.soon') }}
        </div>

        <div class="chevron-wrapper">
          <Chevron />
        </div>
      </div>
    </div>
    <div class="tooltip-wrapper">
      <BuyCryptoTooltip is-home-page />
    </div>
  </section>
</template>

<script setup lang="ts">
import visa from '@st/payments/components/PaymentsModal/assets/visa-white.svg'
import mastercard from '@st/payments/components/PaymentsModal/assets/mastercard.svg'
import googlePay from '@st/payments/components/PaymentsModal/assets/google-pay.svg'
import applePay from '@st/payments/components/PaymentsModal/assets/apple-pay.svg'
import Chevron from '@st/payments/components/NavigationTabs/parts/Chevron.vue'
import BuyCryptoTooltip from '@st/payments/components/BuyCryptoTooltip/BuyCryptoTooltip.vue'
import { useCurrenciesBadges } from './useCurrenciesBadges'

const paymentSystems = [
  { id: 'visa', icon: visa },
  { id: 'mastercard', icon: mastercard },
  { id: 'google-pay', icon: googlePay },
  { id: 'apple-pay', icon: applePay },
]

const { currencies } = useCurrenciesBadges()
const { t } = useI18n()
const { isEnabledBuyCrypto } = useBuyCryptoEnabled()
</script>

<style scoped>
.buy-crypto {
  position: relative;

  display: flex;
  gap: var(--spacing-100);
  align-items: center;
  justify-content: space-between;

  padding: var(--spacing-150) var(--spacing-200);

  background: var(--background-primary);
  border-radius: var(--border-radius-150);
}

.crypto-badges {
  scrollbar-width: none;

  overflow: scroll;
  display: flex;
  align-items: center;

  max-width: 690px;

  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;
}

.crypto-badges::-webkit-scrollbar {
  display: none;
}

.currency-item {
  position: relative;

  display: flex;
  gap: var(--spacing-125);
  align-items: center;

  padding-block: var(--spacing-050);

  border-radius: var(--border-radius-full);

  span {
    font: var(--desktop-text-md-medium);
    color: var(--text-tertiary);
  }

  &:not(:first-child) {
    margin-left: var(--spacing-400);

    &::before {
      content: '';

      position: absolute;
      left: -16px;

      width: 1px;
      height: 13px;

      background-color: var(--border-primary);
    }
  }

  @media screen and (width >= 1440px) and (width <= 1536px) {
    &:last-child {
      display: none;
    }
  }
}

.crypto-actions {
  display: flex;
  gap: var(--spacing-300);
}

.crypto-text {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  white-space: nowrap;

  p {
    margin: 0;
    font: var(--desktop-text-md-semibold);
    color: var(--text-primary);
  }
}

.crypto-payments {
  display: flex;
  gap: var(--spacing-075);
  align-items: center;

  font: var(--desktop-text-xs-medium);
  color: var(--text-tertiary);
}

.crypto-payments-list {
  display: flex;
  gap: var(--spacing-075);

  margin: 0;
  padding: 0;

  list-style: none;
}

.crypto-payments-list-item {
  display: flex;
  align-items: center;
}

.buy-wrapper {
  position: relative;
  display: flex;
  flex-wrap: nowrap;
}

.chevron-wrapper {
  position: absolute;
  top: -8px;
  right: -12px;
}

.soon {
  user-select: none;

  position: absolute;
  top: -8px;
  right: -10px;

  padding: var(--spacing-025) var(--spacing-050);

  font: var(--desktop-text-xxs-medium);
  color: var(--text-secondary);

  background: var(--background-ghost-tertiary);
  border-radius: var(--border-radius-full, 999px);
}

.tooltip-wrapper {
  position: absolute;
  right: 0;
  bottom: -22px;
}
</style>
