<template>
  <section class="main-sport" data-t="main-sport">
    <header class="header">
      <NuxtI18nLink to="/sport" class="title-wrapper">
        <h2 class="title">{{ t('sportHomePage.sport') }}</h2>
        <StIcon name="chevron-right" size="24" class="chevron" />
      </NuxtI18nLink>
      <div class="navigation">
        <StMobileTabs
          v-model="activeTab"
          :tab-items="tabs"
          type="row"
          size="m"
          class="tabs"
          @update:model-value="onTabUpdate"
        />
        <StButton class="all-button" size="m" to="/sport/all" type="gray">
          <span class="button-text">{{ t('sportHomePage.allSport') }}</span>
          <span
            v-if="allEventsCount"
            class="events-count"
            data-t="events-count"
          >
            {{ allEventsCount }}
          </span>
        </StButton>
      </div>
    </header>

    <StSlider
      ref="scroller"
      class="events"
      data-t="events"
      :scroll-step="400"
      :items-gap="12"
      :arrow-offset-vertical="0"
      :arrived-state-offset="{ right: 100 }"
    >
      <StSliderItem v-for="event in eventsForRender" :key="event.id">
        <EventOfTheDay
          :event="event"
          size="s"
          :is-main-event="!!event.isMainEvent"
          class="event"
          data-t="event"
        />
      </StSliderItem>
      <StSliderItem v-for="item in skeletonsCount" :key="item">
        <div class="event event-empty" />
      </StSliderItem>
      <StSliderItem>
        <div class="show-all">
          <StButton
            :to="currentEventsLink"
            icon="chevron-right"
            size="m"
            type="gray"
          />
          <span v-if="currentEventsCount">
            {{ t('sportHomePage.all') }}
            <span class="current-count">{{ currentEventsCount }}</span>
          </span>
        </div>
      </StSliderItem>
    </StSlider>

    <div class="top-tournaments">
      <h3 class="tournametns-title">{{ t('sportHomePage.topTournaments') }}</h3>
      <TopTournaments
        is-live-visible
        class="top-tournaments"
        data-t="top-tournaments"
      />
    </div>
  </section>
</template>

<script setup lang="ts">
import StSlider from '@st/ui/components/StSlider/StSlider.vue'
import EventOfTheDay from '../EventOfTheDay/EventOfTheDay.vue'
import TopTournaments from '../TopTournaments/TopTournaments.vue'
import { useMainSportSection } from './useMainSportSection'

const { t } = useI18n()

const {
  activeTab,
  tabs,
  eventsForRender,
  allEventsCount,
  currentEventsLink,
  currentEventsCount,
} = useMainSportSection()

const scroller = ref<InstanceType<typeof StSlider> | null>(null)
function onTabUpdate() {
  if (scroller.value) {
    scroller.value?.scrollToStart()
  }
}
const MIN_SLIDER_ITEMS_COUNT = 4
const skeletonsCount = computed(() =>
  Math.max(0, MIN_SLIDER_ITEMS_COUNT - eventsForRender.value.length),
)
</script>

<style scoped>
.header {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-100);
}

.events {
  :deep(.st-slider-items) {
    align-items: stretch;
  }
}

.events-count {
  color: var(--text-tertiary);
}

.navigation {
  position: relative;
  display: flex;
  gap: var(--spacing-100);
  padding-bottom: var(--spacing-200);
}

.all-button {
  position: relative;
  flex-shrink: 0;

  &::before {
    content: '';

    position: absolute;
    top: 0;
    bottom: 0;
    left: -48px;

    width: 48px;

    background: linear-gradient(90deg, #11111800, #111118);
  }
}

.button-text {
  overflow: hidden;
  max-width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.tabs {
  flex-grow: 1;
  flex-shrink: 1;
  min-width: 0;
  padding: 0;
}

.event {
  display: flex;
  flex-direction: column;

  width: 400px;
  height: 100%;
  min-height: 206px;

  &.event-empty {
    opacity: 0.4;
    background-color: var(--background-primary);
    border-radius: var(--border-radius-150);
  }
}

.top-tournaments {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-150);
}

.tournametns-title {
  margin: 0;
  padding: var(--spacing-300) 0 var(--spacing-025) 0;

  /* stylelint-disable */
  font-family: 'SF Pro Display';
  font-size: 20px;
  font-weight: 600;
  font-style: normal;
  line-height: 32px;
  color: var(--text-primary);

  /* stylelint-enable */
}

.show-all {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-125);
  align-items: center;
  justify-content: center;

  min-width: 92px;
  height: 190px;

  font: var(--mobile-text-medium);
}

.current-count {
  font: var(--mobile-text-regular);
  color: var(--text-tertiary);
}

.title-wrapper {
  display: inline-flex;
  gap: var(--spacing-050);
  align-items: center;
  align-self: flex-start;

  color: var(--text-primary);
  text-decoration: none;

  &:hover {
    color: var(--text-link);
  }
}

.chevron {
  margin-bottom: -2px; /* stylelint-disable-line */
  transition: transform 0.2s;
}

.title {
  margin: 0;
  font: var(--desktop-text-2xl-semibold);

  &:hover {
    + .chevron {
      transform: translateX(4px);
    }
  }
}
</style>
